import Service from "../Service";

const resource = "instruction/";

export default {
    get(ins, requestID) {
        return Service.post(resource, ins, {
            params: { requestID: requestID },
        });
    },
    pagination(dtr, requestID) {
        return Service.post(resource + "pagination", dtr, {
            params: { requestID: requestID },
        });
    },

    excel(dtr, requestID) {
        return Service.post(resource + "excel", dtr, {
            params: { requestID: requestID },
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
    save(ins, requestID) {
        return Service.post(resource + "save", ins, {
            params: { requestID: requestID },
        });
    },
    reportByWorker(entity, requestID) {
        return Service.post(resource + "reportByWorker", entity, {
            params: { requestID: requestID },
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
        });
    },

};