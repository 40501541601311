<template>
  <v-row>
    <v-col>
      <s-crud
        :edit="!noEdit"
        add
        :remove="!noRemove"
        title="Temas"
        sortable
        :config="config"
        :filter="config.filter"
        @save="isbSave($event)"
        :noBorder="noBorderToolbar"
        @returnObject="returnObject($event, true)"
        @doubleClick="returnObject"
        :noDoubleClick="doubleClickSelect"
      >
        <template slot="filter">
          <v-col cols="12" class="s-col-form">
            <s-select-area
              label="Filtro área"
              v-model="config.filter.AreID"
              :text="areaUser"
            />
          </v-col>
        </template>
        <template scope="props">
          <v-container>
            <v-row>
              <v-col cols="12" md="2" class="s-col-form">
                <s-select-definition
                  :def="1067"
                  v-model="props.item.TypeInstruction"
                  label="Tipo"
                  autofocus
                />
              </v-col>

              <v-col cols="12" md="6" class="s-col-form">
                <s-text v-model="props.item.IsbSubject" label="Tema" />
              </v-col>
              <v-col cols="12" md="4" class="s-col-form">
                <s-select-area
                  v-model="props.item.AreID"
                  :text="props.item.AreName"
                  label="Área"
                />
              </v-col>
              <v-col cols="10" class="s-col-form">
                <s-text
                  v-model="props.item.IsbDescription"
                  label="Descripción"
                />
              </v-col>
              <v-col cols="2" class="s-col-form">
                <s-text
                  v-model="props.item.IsbDuration"
                  label="Tiempo(m)"
                />
              </v-col>
            </v-row>
          </v-container>
        </template>
      </s-crud>
    </v-col>
  </v-row>
</template>

<script>
import _sSubject from "@/services/Management/SubjectService";
export default {
  components: {},
  props: {
    noBorderToolbar: {
      type: Boolean,
      default: false,
    },
    noRemove: {
      type: Boolean,
      default: false,
    },
    noEdit: {
      type: Boolean,
      default: false,
    },
    doubleClickSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      areaUser: "",
      config: {
        model: {
          IsbID: "ID",
          IsbSubject: "string",
          IsbDescription: "string",
          // IsbDuration: "int",
          AreID: "int",
          TypeInstruction: "boolean",
          SecStatus: "status",
        },
        areaCreateSelect: {},
        filter: { AreID: 0 },
        service: _sSubject,
        headers: [
          {
            text: "ID",
            align: "end",
            sortable: false,
            value: "IsbID",
          },
          {
            text: "Tipo",
            value: "TypeInstructionText",
          },
          {
            text: "Tema",
            value: "IsbSubject",
          },
          {
            text: "Descripción",
            value: "IsbDescription",
          },
          {
            text: "Área",
            value: "AreName",
          },
        ],
      },
    };
  },
  created() {
    var info = this.$fun.getUserInfo();
    if ((info != null) & (info.CtrContract != null))
      this.areaUser = info.CtrContract.AreName;
    else this.areUser = "";
  },
  methods: {
    inputFilter(val) {
      this.config.filter.AreID = val || 0;
    },

    inputEdit(val, item) {
      item.AreID = val || 0;
    },
    isbValidated(item) {
      let isValid;
      let message;

      isValid = item.AreID > 0;
      if (!isValid) message = "Seleccione área encargada del tema";
      else {
        isValid = item.IsbSubject.length > 0;
        if (!isValid) message = "Registre tema";
        else {
          isValid = item.IsbDescription.length > 0;
          if (!isValid) message = "Registre descripción del tema";
          else {
            isValid = item.TypeInstruction > 0;
            if (!isValid) message = "Seleccione tipo de evento";
          }
        }
      }

      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },
    isbSave(item) {
      if (this.isbValidated(item)) item.save();
    },
    returnObject(item, refresh) {
      this.$emit("returnObject", item, refresh);
    },
  },
  watch: {},
};
</script>
