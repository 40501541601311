import Service from "../Service";

const resource = "subject/";

export default {
  save(isb, requestID) {
    return Service.post(resource + "save", isb, {
      params: { requestID: requestID },
    });
  },
  pagination(dtr, requestID) {
    return Service.post(resource + "pagination", dtr, {
      params: { requestID: requestID },
    });
  },
  list(filter, requestID) {
    return Service.post(resource + "list", filter, {
      params: { requestID: requestID },
    });
  },
};
