import Service from "../Service";

const resource = "instructionattendance/";

export default {
  pagination(dtr, requestID) {
    return Service.post(resource + "pagination", dtr, {
      params: { requestID: requestID },
    });
  },

  save(ins, requestID) {
    return Service.post(resource + "save", ins, {
      params: { requestID: requestID },
    });
  },
  excel(dtr, requestID) {
    return Service.post(resource + "excel", dtr, {
      params: { requestID: requestID },
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};
