<template>
  <s-crud
    @returnObject="refresh($event)"
    no-border
    title="Registro de Capacitaciones"
    :config="config"
    @save="sav($event)"
    add
    @addEvent="clearForm()"
    edit
    @rowSelected="rowSelected($event)"
    remove
    :filter="filter"
  >
    <template scope="props">
      <v-container style="" v-if="props.item != null">
        <v-row>
          <v-col lg="6" cols="12">
            <v-row>
              <v-col lg="4" class="s-col-form" cols="12">
                <s-date
                  label="Fecha"
                  :min="$fun.getDate()"
                  v-model="props.item.TvDate"
                ></s-date>
              </v-col>
              <v-col lg="4" class="s-col-form" cols="12">
                <s-select-definition
                  v-model="props.item.TypeInstruction"
                  id="DedValue"
                  :def="1357"
                  label="Tipo Capacitación"
                  @input="removeAllMaterial(props.item.TypeVisit)"
                />
              </v-col>
              <v-col lg="4" class="s-col-form">
                <s-select-definition
                  :def="1356"
                  v-model="props.item.TypeCondition"
                  label="Tipo Condición"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="8" class="s-col-form" cols="12">
                <s-text
                  label="Observaciones"
                  v-model="props.item.InsObservation"
                />
              </v-col>
              <v-col lg="4" class="s-col-form" cols="12">
                <s-select-definition
                  :def="1358"
                  v-model="props.item.TypeModality"
                  label="Modalidad"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="8" class="s-col-form" cols="12">
                <s-select-worker
                  v-model="props.item.NtpID"
                  id="NtpID"
                  label="Gestor Responsable"
                  :text="props.item.Supervisor"
                  @input="getSupervData(props.item.NtpID)"
                />
              </v-col>
              <v-col lg="4" class="s-col-form" cols="12">
                <s-select-definition
                  v-model="props.item.TvStatus"
                  :def="1152"
                  label="Estado"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:SecStatus="{ row }">
      <v-chip
        style="margin: 0px"
        x-small
        :color="row.TvStatus == 1 ? 'success' : 'error'"
      >
        {{ row.SecStatus == 1 ? "Activo" : "Inactivo" }}
      </v-chip>
    </template>
  </s-crud>
</template>

<script>
import SSelectWorker from "../../../components/Utils/Worker/sSelectWorker.vue";

import _sInstruction from "@/services/Management/InstructionService";
import _sInstructionInstructor from "@/services/Management/InstructionInstructorService";
import _sInstructionAttendance from "@/services/Management/InstructionAttendanceService";
import sToolbarPerson from "@/components/Utils/Person/sToolbarPersonBQ";
import sToolbarSubject from "@/views/Management/Instruction/SToolbarSubject.vue";
import { now } from 'moment';
export default {
  components: {
    SSelectWorker,
    sToolbarPerson,
    sToolbarSubject,
  },
  props: {
    defID: 0,
    defDescription: "",
  },
  data: () => ({
    config: {
      title: "Registro de Visitas Técnicas",
      service: _sInstruction,
      model: {
        TvID: "ID",
        TvDate: "date",
        FltID: "int",
        NtpID: "int",
        DedValue: "int",
        SecStatus: "status",
        TvStatus: "status",
        TvObservation: "string",
        TvCultiveID: "int",
      },
      headers: [
        {
          text: "ID",
          value: "TvID",
          width: "60",
          align: "end",
          sorteable: true,
        },
        {
          text: "Fecha",
          value: "TvDate",
          sorteable: true,
        },
        {
          text: "Supervisor",
          value: "Supervisor",
          sorteable: false,
        },
        {
          text: "Productor",
          value: "PrdText",
          sorteable: false,
        },
        {
          text: "Lote",
          value: "FltName",
          sorteable: true,
        },

        {
          text: "Tipo Visita",
          value: "TipoVisita",
          align: "center",
          sorteable: false,
        },
        {
          text: "Estado",
          value: "TvStatus",
          width: "80",
          align: "center",
          sorteable: false,
        },
      ],
    },
    currentItem: "tab-Funciones",
    filter: {
      InsDate:  Date.now(),
      InsDateEnd:  Date.now(),
      IsbID: 0,
      AreID: 0,
      SecStatus: 1,
      CtrStatus: 1,
    },
    ///---
    ins: {
      InsDate: "",
      InsObservation: "",
      InsID: 0,
    },
    iinConfig: {
      service: _sInstructionInstructor,
      model: {
        InsID: "int",
        IinID: "ID",
        NtpID: "int",
        PrsDocumentNumber: "string",
        NtpFullName: "string",
        WkrID: "int",
        IsbID: "int",
        IsbSubject: "string",
        SecStatus: "status",
      },
      headers: [
        {
          text: "Instructor",
          value: "NtpFullName",
        },
        {
          text: "Tema",
          value: "IsbSubject",
          width: "20%",
        },
        {
          text: "Tipo",
          value: "TypeInstructionText",
          width: "20%",
        },
        {
          text: "Área",
          value: "AreAbbreviation",
          width: "20%",
        },
      ],
    },
    iasConfig: {
      service: _sInstructionAttendance,
      model: {
        InsID: "int",
        IasID: "ID",
        NtpID: "int",
        PrsDocumentNumber: "string",
        NtpFullName: "string",
        WkrID: "int",
        IsbID: "int",
        SecStatus: "status",
      },
      headers: [
        // {
        //   text: "ID",
        //   value: "IasID",
        //   align: "end",
        //   width: "15%",
        // },
        {
          text: "Doc",
          value: "PrsDocumentNumber",
          align: "center",
          width: "20%",
        },
        {
          text: "Asistente",
          value: "NtpFullName",
        },
        {
          text: "Área",
          value: "AreAbbreviation",
          width: "30%",
        },
      ],
    },
  }),
  created() {
    var inf = this.$fun.getUserInfo();
    if (inf.CtrContract != null) {
      this.filter.AreID = inf.CtrContract.AreID;
      this.filter.AreName = inf.CtrContract.AreName;
      this.filter.InsDate = now
    }
  },
  computed: {},
  methods: {
    clearForm() {},
    rowSelected(items) {
      if (items.length < 1) return;
    },
    getSupervData(ntpid) {
      if (ntpid > 0) {
        _technicalVisit
          .listdatossupervisor(ntpid)
          .then((response) => {
            let data = response.data;
            data.forEach((element) => {
              let objSpv = {
                NtpID: element.NtpID,
                PrsID: element.PrsID,
                ZonID: element.ZonID,
                ZonDescription: element.ZonDescription,
              };
              this.itemsDatosSuperv = [];
              this.itemsDatosSuperv.push(objSpv);
              console.log(this.itemsDatosSuperv);
            });
          })
          .catch((err) => {
            console.error("err");
          });
      }
    },
    //Intructor
    returnObjectIin(item) {},
    iinSave(item) {
      item.InsID = this.ins.InsID;

      if (this.iinValidate(item)) {
        // SI TIENE INSTRUCCION ENTONCES GUARDA EL DETALLE SI NO GUARDA PRIMERO LA INSTRUCCION Y LUEGO EL DETALLE
        if (item.InsID > 0) this.iinSaveItem(item);
        else {
          if (this.insValidate()) {
            this.insSave().then((r) => {
              this.ins.InsID = r.data.InsID;
              item.InsID = this.ins.InsID;
              if (item.InsID > 0) this.iinSaveItem(item);
            });
          }
        }
      }
    },

    //Temas
    iinReturnSubject(value, item) {
      value = value || {};
      item.IsbID = value.IsbID;
      item.TypeInstruction = value.TypeInstruction;

      this.$nextTick(function () {
        this.iinPersonFocus();
      });
    },

    //Asistentes
    iasFocus(event) {
      this.$refs.tbIasPerson.focus();
    },
    iasSaveItem(item) {
      item.save();
      this.iasFocus();
    },
    iasSave(item) {
      item.InsID = this.ins.InsID;

      if (this.iasValidate(item)) {
        // SI TIENE INSTRUCCION ENTONCES GUARDA EL DETALLE SI NO GUARDA PRIMERO LA INSTRUCCION Y LUEGO EL DETALLE
        if (item.InsID > 0) this.iasSaveItem(item);
        else {
          if (this.insValidate()) {
            this.insSave().then((r) => {
              this.ins.InsID = r.data.InsID || 0;
              item.InsID = this.ins.InsID;
              if (item.InsID > 0) this.iasSaveItem(item);
            });
          }
        }
      }
    },
  },
};
</script>
