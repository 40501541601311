import Service from "../Service";

const resource = "instructioninstructor/";

export default {
    pagination(dtr, requestID) {
        return Service.post(resource + "pagination", dtr, {
            params: { requestID: requestID },
        });
    },

    save(ins, requestID) {
        return Service.post(resource + "save", ins, {
            params: { requestID: requestID },
        });
    },
};