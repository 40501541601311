<template>
<div style="padding-top:-2px;margin-top:-2px;">
<v-label ><b>Tema</b></v-label>
  <v-autocomplete
    ref="cbSubject"
    v-model="valueLocal"
    item-value="IsbID"
    item-text="IsbSubject"
    :items="subjects"
    dense
    outlined
    hide-details
    @input="input"
    :autofocus="autofocus"
    :clearable="clearable"
  >
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title v-html="data.item.IsbSubject" />
        <v-list-item-subtitle v-html="data.item.AreName" />
      </v-list-item-content>
    </template>

    <template v-slot:append-outer v-if="add">
      <v-icon
        color="success"
        @click="dialogSubject = true"
        v-text="'mdi-plus-circle-outline'"
        class="mt-1"
      />

      <v-dialog
        v-model="dialogSubject"
        persistent
        max-width="800px"
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
        <v-card tile>
          <v-toolbar flat dark color="primary" dense>
            <v-btn icon dark small @click="dialogSubject = false" class="ml-1">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Listado de Temas</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="dialogSubject = false">
                Seleccionar
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <s-subject
              @returnObject="returnObject"
              noEdit
              noRemove
              noBorderToolbar
              doubleClickSelect
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </v-autocomplete>
</div>
</template>

<script>
import _sSubject from "@/services/Management/SubjectService";
import SSubject from "@/views/Management/InstructionSubject";

export default {
  components: {
    SSubject,
  },
  props: {
    autofocus: { type: Boolean, default: false },
    add: { type: Boolean, default: false },
    value: {
      required: false,
    },
    clearable: {
      required: false,
    },
    filter: {
      type: Object,
      default() {
        return {
          IsbSubject: "",
          AreID: 0,
          TypeInstruction: 0,
        };
      },
    },
  },
  data() {
    return {
      subjects: [],
      dialogSubject: false,
      valueLocal: 0,
    };
  },
  created() {
    this.valueLocal = this.value;
    this.initialize();
  },
  watch: {
    value() {
      this.valueLocal = this.value;
    },
    filter() {
      this.initialize();
    },
  },
  computed: {},
  methods: {
    initialize() {
      _sSubject.list(this.filter, this.$fun.getUserID()).then((r) => {
        this.subjects = r.data;
      });
    },
    input(value) {
      this.$emit("input", value);

      var val;
      if (value > 0) val = this.subjects.filter((x) => x.IsbID == value)[0];
      else val = null;

      this.$emit("returnSubject", val);
    },
    keydownEnter(e) {
      this.$emit("keydown", e);
    },
    focus() {
      this.$refs.cbSubject.focus();
    },
    returnObject(isb, refresh) {
      if (refresh) {
        _sSubject.list(this.filter, this.$fun.getUserID()).then((r) => {
          this.subjects = r.data;
          this.dialogSubject = false;
          this.valueLocal = isb.IsbID;
          this.input(this.valueLocal);
        });
      } else {
        this.dialogSubject = false;
        this.valueLocal = isb.IsbID;
        this.input(this.valueLocal);
      }
    },
  },
};
</script>
